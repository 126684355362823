body {
  background-color: #00001e;
  margin: 0;
  // margin: 0;
  // /* height: 100vh; */
  // height: 100%;
  // width: 100%;
  // background-position: top center;
  // background-repeat: no-repeat;
  // font-family: "open-sans";
}

// #root {
//   height: 100vh;
//  // margin: 0;
//   // min-height: 100vh;
//   // height: 100%;
//   // width: 100%;
//   // font-family: "open-sans";
// }

.lb-home {
  text-align: center;
  margin-top: 40px;
  height: 100%;
  overflow-x: hidden;
}

.header-section {
  img {
    padding-top: 80px;
    max-width: 130px;
  }
}

.lb-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lb-content-section {
  .scan-button p {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    width: 335px;
    margin-bottom: 8px;
  }
  .manual-input p {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    width: 335px;
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.lb-footer {
  display: flex;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 10px;
  max-width: 350px;
  margin: 0 auto;
  justify-content: center;
  gap: 16px;

  :hover {
    cursor: pointer;
  }
}

.address-input {
  height: 48px;
  width: 335px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 0px 13px 16px;
  gap: 10px;
  // background: #f6f6f7;
  // border: 1px solid rgba(90, 81, 63, 0.3);
  background-color: #ffffff;
  border: 1px solid rgba(0, 213, 255, 0.3);
  text-transform: uppercase;
  font-style: italic;
  font-family: "segoe";
}

.address-input:focus-visible {
  outline: none;
}

@media screen and (max-width: 800px) {
  #root {
    text-align: center;
  }
}

.main-button {
  padding: 10px;
  border-radius: 50px;
  border: 1px solid rgb(69, 34, 14);
}

.language-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 32px;
  height: 100%;
  overflow-x: hidden;
}

.page-title {
  // color: #ba5b2d;
  width: 335px;
  height: 96px;
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.language-buttons-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.langpage-footer {
  position: absolute;
  width: 100%;
  bottom: 24px;
}

.language-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px 8px 8px;
  gap: 4px;
  width: 335px;
  height: 48px;
  // background: #5a513f;
  background-color: #00d5ff;
  border: none;
  // font-family: "open-sans";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  // text-transform: uppercase;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

.gotoscan-button {
  height: 48px;
  width: 335px;
  border-radius: 0px;
  padding: 11px 0px 11px 0px;
  margin-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 0px;
  gap: 10px;
  // background: #5a513f;
  // border: 1px solid #5a513f;
  background-color: #00d5ff;
  border: 1px solid #00d5ff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  color: white;
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.page-title-home {
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.home-texts {
  // font-family: "open-sans";
  font-family: "segoe";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  // color: #5a513f;
  margin: 8px 20px;
}

.scan-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "segoe";
}

.manual-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "segoe";
}

.lang-select {
  border: none;
}

.css-b62m3t-container .css-13cymwt-control {
  border: none !important;
  background-color: transparent !important;
}

.css-b62m3t-container .css-t3ipsp-control {
  border: none !important;
  background-color: transparent !important;
}

//input
.css-13cymwt-control .css-qbdosj-Input {
  display: none !important;
}

.css-t3ipsp-control .css-qbdosj-Input {
  display: none !important;
}

//image
.css-13cymwt-control .css-1fdsijx-ValueContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-13cymwt-control .css-hlgwow {
  padding: 0 !important;
}

.css-t3ipsp-control:hover {
  border-color: transparent !important;
  border: none !important;
}

.css-t3ipsp-control .css-1fdsijx-ValueContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-hlgwow {
  padding: 0 !important;
}

//separator
.css-13cymwt-control .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-t3ipsp-control .css-1u9des2-indicatorSeparator {
  display: none !important;
}

//arrow
.css-13cymwt-control .css-1xc3v61-indicatorContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-1xc3v61-indicatorContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-15lsz6c-indicatorContainer {
  padding: 0 !important;
}

//dropdown
.css-b62m3t-container .css-1nmdiq5-menu {
  // box-shadow: none;
  // background-color: transparent;
  margin-top: 5px !important;
  right: 0 !important;
}

.css-b62m3t-container .css-1nmdiq5-menu .css-tr4s17-option {
  background-color: #e7e7e8 !important;
}

.css-b62m3t-container #react-select-2-listbox {
  margin-top: 5px !important;
  right: 0!important;
}

#react-select-2-listbox {
  margin-top: 5px !important;
  right: 0 !important;
}

.footer {
  //position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-family: "segoe";
  font-size: 12px;
  // margin-top: 80px;
  // margin-bottom: 24px;
  p {
    font-size: 12px;
    color: #ffffffcf
  }
  .ccLogo{
    height: 32px;
  }
  .galaxisLogo{
    height: 16px;
  }
  .poweredBy {
    margin-top: 0;
    margin-bottom: 8px;
  }
  .ccHolder {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.ccLink {
  color: #00d5ff;
  text-decoration: none;
  font-family: "segoe-bold";
}

.galaxisLink {
  color: rgb(246, 76, 4);
  text-decoration: none;
  font-family: "segoe-bold";
}

.holder {
  position: relative;
}

.sticky-elements {
  position: fixed;
  bottom: 0;
}

.footer-holder {
  width: 100vw;
  // margin-top: 48px;
  margin-bottom: 74px;
}

.absolute-footer-holder {
  position: fixed;
  bottom: 74px;
  width: 100%;
  text-align: center;
}
