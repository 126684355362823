.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 20px 0 20px;
}

.header .logo {
  height: 34px;
  width: 156px;
}

.language-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.language-picker .arrow {
  width: 12px;
  height: 6px;
}

.language-picker .lang {
  width: 20px;
  height: 20px;
}
